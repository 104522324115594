export class FileUploadModel {

    public id: number;
    public name: string;
    public url: string;
    public thumbnailUrl: string;
    public extension: string;
    public displayName: string;

}
